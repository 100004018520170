import { tns } from 'tiny-slider';

const topstory = document.querySelector('.tns-topstory');
if (topstory) {
    new tns({
        container: topstory,
        nav: false,
        loop: false,
        gutter: 20,
        edgePadding: 20,
        responsive: {
            400: {
                fixedWidth: 360
            },
            992: {
                fixedWidth: 360
            },
            1300: {
                edgePadding: 150
            },
            1400: {
                edgePadding: 50,
                fixedWidth: 460
            },
            1500: {
                edgePadding: 100
            },
            1600: {
                edgePadding: 150
            },
            1700: {
                edgePadding: 200
            },
            1800: {
                edgePadding: 250
            }
        }
    });
}